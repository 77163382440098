<template>
  <div class="adManagement-container">
    <el-row class="datePicker">
      <ad-date-range-pick
        v-model="dateRange"
        size="small"
      ></ad-date-range-pick>
    </el-row>
    <el-row>
      <!-- 头部tab-->
      <el-tabs
        type="border-card"
        v-model="activeTab"
        @tab-click="handleClickTab"
      >
        <el-tab-pane
          name="campaign"
          :lazy="true"
        >
          <span slot="label"
            >广告系列
            <el-button
              type="primary"
              round
              size="mini"
              v-if="selectCampaigns.filter((v) => v.level == 'campaign').length"
              >已选{{ selectCampaigns.filter((v) => v.level == 'campaign').length }}项</el-button
            >
            <el-button
              class="el-icon-refresh"
              type="text"
              @click="refreshTable('campaignTable')"
              v-if="activeTab == 'campaign'"
            ></el-button>
          </span>
          <ad-table
            :date-range="dateRange"
            level="campaign"
            :account-id="accountId"
            :init-data="initData"
            :fields="fields"
            :selectCampaigns="selectCampaigns"
            @selection-change="handleSelectionChange"
            :activeLevel="activeTab"
            :adsCountObj="adsCountObj"
            ref="campaignTable"
            @select-tab="selectTab"
          ></ad-table>
        </el-tab-pane>
        <el-tab-pane
          name="adset"
          :lazy="true"
        >
          <span slot="label"
            >广告组
            <el-button
              type="primary"
              round
              size="mini"
              v-if="selectAdsets.filter((v) => v.level == 'adset').length"
              >已选{{ selectAdsets.filter((v) => v.level == 'adset').length }}项</el-button
            >
            <el-button
              class="el-icon-refresh"
              type="text"
              @click="refreshTable('adsetTable')"
              v-if="activeTab == 'adset'"
            ></el-button>
          </span>
          <ad-table
            :date-range="dateRange"
            level="adset"
            :account-id="accountId"
            :init-data="initData"
            :fields="fields"
            @selection-change="handleSelectionChange"
            :activeLevel="activeTab"
            :adsCountObj="adsCountObj"
            :selectCampaigns="selectCampaigns"
            ref="adsetTable"
            @click="refreshTable('adsetTable')"
            @select-tab="selectTab"
          ></ad-table>
        </el-tab-pane>
        <el-tab-pane
          name="ad"
          :lazy="true"
        >
          <span slot="label"
            >广告
            <el-button
              type="primary"
              round
              size="mini"
              v-if="selectAds.filter((v) => v.level == 'ad').length"
              >已选{{ selectAds.filter((v) => v.level == 'ad').length }}项</el-button
            >
            <el-button
              class="el-icon-refresh"
              type="text"
              @click="refreshTable('adTable')"
              v-if="activeTab == 'ad'"
            ></el-button>
          </span>
          <ad-table
            :date-range="dateRange"
            level="ad"
            :account-id="accountId"
            :init-data="initData"
            :fields="fields"
            @selection-change="handleSelectionChange"
            :activeLevel="activeTab"
            :adsCountObj="adsCountObj"
            :selectCampaigns="selectCampaigns"
            :selectAdsets="selectAdsets"
            ref="adTable"
            @click="refreshTable('adTable')"
            @setMulitAccount="setMulitAccount"
          ></ad-table>
        </el-tab-pane>
        <el-tab-pane name="drafBox">
          <span slot="label"
            >草稿箱
            <el-button
              type="info"
              round
              size="mini"
              >{{ adsCountObj.drafts }}</el-button
            >
          </span>
          <div class="tableWrap">
            <draft-box @get-draft-num="getDraftNum" :multiAccount="true"></draft-box>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <!-- 保存为我的筛选条件 -->
    <el-dialog
      title="保存查询条件"
      :visible.sync="saveFilterShow"
      width="500px"
    >
      <div>
        <el-form label-width="120px">
          <el-form-item label="查询条件名称">
            <el-input
              placeholder="请输入"
              v-model="filterName"
            ></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: right">
          <span
            slot="footer"
            class="dialog-footer"
          >
            <el-button
              type="primary"
              @click="saveMyFilter"
              >保存</el-button
            >
            <el-button
              @click="
                saveFilterShow = false;
                filterName = '';
              "
              >取消</el-button
            >
          </span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import DraftBox from './components/DraftBox.vue';
import {
  report,
  breakdownRules,
  reportConsts,
  breakdown,
  myColumns,
  adsCount,
  myFilters,
  saveFilter,
  deleteFilter,
} from '@/api/adManagement.js';
import AdDateRangePick from '@/views/adManagement/components/AdDateRangePick';
import AdCellInlineEditBudget from '@/views/adManagement/components/table/AdCellInlineEditBudget';
import AdCellName from '@/views/adManagement/components/table/AdCellName';
import AdTable from '@/views/adManagement/components/table/multiAccountAdTable';
const _ = require('lodash');
export default {
  data() {
    return {
      adsetTableCount: 0,
      adTableCount: 0,
      selection: {},
      selectedRowScopes: [],
      dateRange: {
        actionAttributionWindows: [],
        dateRange: [],
      },
      filterIcon: require('@/assets/icon/filter.png'),
      adType: '创建标准广告',
      keyword: '',
      tableData: [],
      disAbled: false,
      loading: false,
      accountId: this.$store.getters.accountId,
      level: 'campaign',
      activeTab: this.$store.getters.adActiveTab,
      fields: [],
      params: {
        account_id: this.accountId,
        action_attribution_windows: [],
        after: '',
        date_end: '2020-10-01',
        date_start: '2020-02-20',
        fields: [],
        filtering: [],
        object_filtering: [],
        keyword: '',
        level: 'campaign',
        page_length: 50,
        sort: [],
      },
      after: '',
      expandAll: false, //是否展开所有下级，当加载细分的时候默认展开
      expandKeys: [],
      scrollDisable: false, //表格无限滚动是否禁用
      loadText: '滚动加载更多', //默认加载文字
      breakdownRulesArr: [],
      initData: {},
      breakdowms: {
        time: '',
        delivery: '',
      },
      breakdownGroup: [],
      maps: new Map(),
      infiniteScrollDisabled: false,
      lazy: true,
      // 广告数量
      adsCountObj: {},
      selectCampaigns: [],
      selectAdsets: [],
      selectAds: [],
      selectIds: {
        selectCampaigns: [],
        selectAdsets: [],
        selectAds: [],
      },
      myCols: {
        myColumnsArr: [],
        myColumsName: '',
        myColumsId: '',
      },
      // 筛选条件
      filterList: [], //我的自定义筛选条件
      cusFilterList: [{ field: null, operator: '', value: null, value1: [] }],
      filterId: 0,
      filterShow: false,
      checkList: [],
      filterName: '',
      saveFilterShow: false,
      accountList:[]
    };
  },
  components: {
    AdTable,
    AdCellName,
    AdCellInlineEditBudget,
    AdDateRangePick,
    DraftBox,
  },
  filters: {
    showName(item) {
      return item ? (item['name'] ? item['name'] : '') : '请选择';
    },
  },
  computed: {
    // checkList(){
    //     let list = this.params.filtering.filter(v=>v.filed == 'status');
    //     if(list.length){
    //         return list[0].value1;
    //     }else{
    //         this.params.filtering.push({filed:'status',operator:'IN',value1:[],value:''})
    //         return this.params.filtering.filter(v=>v.filed == 'status')[0].value1;
    //     }
    // },
    campaignNum() {
      return this.$refs.campaignTable && this.$refs.campaignTable.summary
        ? this.$refs.campaignTable.summary.total_count
        : this.adsCountObj.campaigns;
    },
    adsetNum() {
      console.log(this.$refs.adsetTable);
      return this.$refs.adsetTable && this.$refs.adsetTable.summary
        ? this.$refs.adsetTable.summary.total_count
        : this.adsCountObj.adsets;
    },
    adNum() {
      return this.$refs.adTable && this.$refs.adTable.summary
        ? this.$refs.adTable.summary.total_count
        : this.adsCountObj.ads;
    },
    // accountList(){
    //   console.log(this.$refs['campaignTable'],"this.$refs['campaignTable']");
    //   return this.$refs['campaignTable']?.accountList || []
    // },
  },
  watch: {
    level: {
      handler() {
        // if (this.isArrayChange(this.selectIds.selectCampaigns, this.selectCampaigns)) {
        this.selectIds.selectCampaigns = this.selectCampaigns.filter((v) => v.level == 'campaign');
        // }
        // if (this.isArrayChange(this.selectIds.selectAdsets, this.selectAdsets)) {
        this.selectIds.selectAdsets = this.selectAdsets.filter((v) => v.level == 'adset');
        // }
        // if (this.isArrayChange(this.selectIds.selectAds, this.selectAds)) {
        this.selectIds.selectAds = this.selectAds.filter((v) => v.level == 'ad');
        // }
      },
    },
    selectCampaigns: {
      deep: true,
      handler() {
        console.log('------selectCampaigns', this.selectCampaigns);
      },
    },
    'this.$store.getters.adActiveTab'() {
      this.activeTab = this.$store.getters.adActiveTab;
      // console.log(this.activeTab);
      if (this.activeTab !== 'drafBox') {
        this.level = this.activeTab;
      }
    },
    dateRange() {
      this.params.action_attribution_windows = this.dateRange.actionAttributionWindows;
      this.params.date_start = this.dateRange.dateRange[0];
      this.params.date_end = this.dateRange.dateRange[1];
      localStorage.setItem('adDateRange', JSON.stringify(this.dateRange));
      // this.$store.commit('setDateRange',this.dateRange)
      // this.$store.commit("adsDate/setDateRange",this.dateRange);
    },
  },
  async mounted() {
    if (localStorage.getItem('adDateRange')) {
      this.dateRange = { ...JSON.parse(localStorage.getItem('adDateRange')) };
    }
    this.$store.dispatch('app/openSideBar');
    // this.adsCount();
    await this.getInitData();
    // await this.myColumns();
    this.getMyFilter();
    // this.breakdownRules();
    // ctrl+d 快捷打开复制广告系列-广告组-广告
    this.listenerCtrlD();
  },
  // 实例销毁之前对点击事件进行解绑
  beforeDestroy() {
    // window.removeEventListener('click', this.clickOther);
    window.removeEventListener('keydown', this.ctrlDMethod, true);
  },
  // 注册自定指令
  directives: {
    'infinite-scroll': {
      bind(el, binding) {
        const selectWrap = el.querySelector('.el-table__body-wrapper');
        selectWrap.addEventListener('scroll', function () {
          let sign = binding.value.distance;
          const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight;
          if (scrollDistance <= sign) {
            binding.value.load();
          }
        });
      },
    },
  },
  methods: {
    getListCount(tableName, val) {
      this[`${tableName}Count`] = val;
    },
    ctrlDMethod() {
      if (event.key == 'd' && event.ctrlKey) {
        event.preventDefault();
        // if (this.$refs[`${this.activeTab}Table`] && this.selectedRowScopes.length) this.$refs[`${this.activeTab}Table`].showDrawer('copy', this.selectedRowScopes, this.activeTab)
        if (!this.selectedRowScopes.length) return false;
        if (this.$refs[`${this.activeTab}Table`] && this.selectedRowScopes.length)
          this.$refs[`${this.activeTab}Table`].editCopyShow = true;
        // console.log("this.selectedRowScopes",this.selectedRowScopes)
      }
    },
    listenerCtrlD() {
      if (this.selectedRowScopes && this.selectedRowScopes.length) {
        window.addEventListener('keydown', this.ctrlDMethod, true);
      }
    },
    // 刷新表格数据
    refreshTable(ref) {
      this.$refs[ref].debouncedReloadTable();
    },
    // 获取草稿数量
    getDraftNum(v) {
      this.adsCountObj.drafts = v;
    },
    // 获取常量数据
    async getInitData() {
      await reportConsts().then((res) => {
        this.initData = res.data;
      });
    },
    // 广告数量合计
    adsCount() {
      let params = { accountId: this.$store.getters.accountId };
      adsCount(params).then((res) => {
        if (res.code == 0) {
          this.adsCountObj = { ...this.adsCountObj, ...res.data };
          this.numObj = { ...res.data };
          // console.log("res.data,this.adsCountObj",res.data,this.adsCountObj)
        }
      });
    },
    // 保存为我的筛选条件
    saveMyFilter() {
      let data = {
        name: this.filterName,
      };
      let filters = this.params.filtering.filter((v) => v.field != 'status' && v.field != 'objective');
      data.filtering = filters;
      this.$showLoading();
      saveFilter(JSON.stringify(data)).then((res) => {
        // console.log(res);
        this.$hideLoading();
        if (res.code == 0) {
          this.saveFilterShow = false;
          this.filterName = '';
          this.getMyFilter();
        }
      });
    },
    // 获取我的查询条件
    getMyFilter() {
      myFilters().then((res) => {
        this.filterList = res.data;
      });
    },
    // 监听选择表格的选择项
    handleSelectionChange(level, selection) {
      this.selectedRowScopes = [...selection];
      if (level == 'campaign') {
        this.selectCampaigns = selection;
        this.selectAdsets = [];
        this.selectAds = [];
      } else if (level == 'adset') {
        this.selectAdsets = selection;
        this.selectAds = [];
      } else if (level == 'ad') {
        this.selectAds = selection;
      }
      // ctrl+d 快捷打开复制广告系列-广告组-广告
      this.listenerCtrlD();
    },
    handleClickTab(v) {
      console.log('handleClickTab', v, v.id, v.name);
      this.$store.commit('adActiveTab/setActiveTab', v.name);
      if (v.name !== 'drafBox') {
        if (v.name == 'adset' && v.id) {
          this.selectIds.selectCampaigns = v.id;
        } else if (v.name == 'ad' && v.id) {
          this.selectIds.selectAdsets = v.id;
        }
        this.level = this.activeTab;

        if (v.name == 'campaign') {
          if (this.$refs.campaignTable) this.$refs.campaignTable.tabChangeReloadTable('campaign');
        }
        if (v.name == 'adset') {
          if (this.$refs.adsetTable) this.$refs.adsetTable.tabChangeReloadTable('adset');
        }
        if (v.name == 'ad') {
          if (this.$refs.adTable) this.$refs.adTable.tabChangeReloadTable('ad');
        }
        // console.log(this.selectIds);
      }else{
        this.accountList = this.$refs['campaignTable'].accountList
        console.log(this.$refs['campaignTable'].accountList,this.accountList);
      }
    },
    setMulitAccount(v){
      console.log(this.$refs['campaignTable'].accountList,this.accountList,"this.$refs['campaignTable'].accountList,this.accountList");
      this.accountList = [...v]
    },
    selectTab(v) {
      console.log('selectTab(v)', JSON.parse(JSON.stringify(v)));
      this.activeTab = v.name;

      if (v.name !== 'drafBox') {
        if (v.name == 'adset' && v.row) {
          this.selectCampaigns = [v.row];
          // this.selectIds.selectCampaigns = [v.row];
          this.$nextTick(() => {
            if (this.$refs.adsetTable) this.$refs.adsetTable.tabChangeReloadTable('adset');
          });
        } else if (v.name == 'ad' && v.row) {
          this.selectAdsets = [v.row];
          // this.selectIds.selectAdsets = [v.row];
          this.$nextTick(() => {
            if (this.$refs.adTable) this.$refs.adTable.tabChangeReloadTable('ad');
          });
        }
      }
    },
  },
};
</script>
<style lang="scss">
.adManagement-container .el-tabs__content {
  padding: 0;
}

.adManagement-container {
  position: relative;
  .el-table .caret-wrapper {
    width: 0;
  }
  .datePicker {
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 2;
  }
}

.toolsCheckTips {
  .el-checkbox,
  .el-radio {
    line-height: 36px;
  }
}

.dropselect {
  .el-select-dropdown__item {
    padding: 0;

    span {
      display: block;
      padding: 0 20px;
    }
  }
}

.el-table__body-wrapper {
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }
}

.statusWarp {
  display: flex;
  align-items: center;

  .pointer {
    flex: 8px 0 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.breakdownList {
  li {
    line-height: 26px;
  }
}

.cusFilter {
  padding: 0 10px 0 20px;

  .cusFilterList {
    .cusFilterItem {
      margin-bottom: 10px;
    }
  }
}

.el-dropdown-menu__item {
  &.active {
    background: #ecf5ff;
    color: #66b1ff;
  }

  .columnName {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      p {
        display: block;
      }
    }

    div {
      p {
        flex: 60px 0 0;
        display: none;

        i {
          margin: 0 5px;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
}

.tagInput {
  width: 30px;

  input {
    width: auto;
    border: none;
    outline: none;
    background: transparent;
    padding: 0 5px;
    width: 30px;
    border-bottom: 1px dashed #fff;
    color: #fff;
    height: 18px;
    line-height: 18px;
  }
}

.el-popover {
  min-width: 100px;
}
</style>
<style lang="scss" scoped>
.tableWrap {
  padding: 16px;
  padding-top: 0;

  .loadMore {
    text-align: center;
    line-height: 28px;
    color: #999;
  }
}

.el-table .caret-wrapper {
  width: 0;
}

.adManagement-container {
  width: 100%;
  height: calc(100vh - 82px);
  background: #fff;

  .filterWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ecf5ff;

    .el-image {
      flex: 14px 0 0;
      margin-left: 5px;
    }

    .paramsFilters {
      flex: 1;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      .el-tag {
        margin: 3px;
        display: flex;
        align-items: center;

        .checkbox-group {
          padding: 0 10px;

          .el-checkbox {
            display: block;
          }
        }
      }
    }

    .rightIcon {
      flex: 40px 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      i {
        font-size: 15px;
        font-weight: bold;
        margin: 0 5px;
        cursor: pointer;
        color: #666;
      }
    }
  }

  .el-tabs__item {
    .el-button {
      padding: 4px 10px;
      margin-left: 5px;
    }
  }

  .table-top-bar {
    // position: absolute;
    // top: 56px;
    display: flex;
    align-items: center;
    width: 100%;
    // height: 32px;
    padding: 16px;
    padding-left: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .rightTools {
      flex: 260px 0 0;
      display: flex;
      align-items: center;
      text-align: right;
      justify-content: flex-end;

      .tools {
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .toolsItem {
          margin: 0 4px;
        }
      }

      .searchInput {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .el-button {
          color: #666;
          font-size: 14px;
          margin: 0 5px;
        }
      }
    }

    .midFilter {
      flex: 1;
      padding: 0 10px;
    }

    .table-top-bar-right {
      flex: 1 1 0%;
      display: flex;
      align-items: center;
      position: relative;
      transition: all 0.3s linear 0s;
      // padding-right: 140px;
      .filter-btn {
        display: flex;
        align-items: center;
        height: 32px;
        font-size: 12px;
        color: #8c8c8c;
        margin-left: 12px;
        padding: 0 4px;
        border-radius: 2px;
        transition: all 0.3s linear 0s;
        cursor: pointer;

        img {
          margin-right: 4px;
        }
      }

      .filter-btn:hover {
        background: #eaeaed;
      }

      .filter-operation {
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        width: auto;
        padding-left: 10px;
        transition: all 0.3s linear 0s;

        .filter-operation-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          transition: all 0.3s linear 0s;
          cursor: pointer;

          i {
            color: gray;
          }
        }

        .filter-operation-item:hover {
          background: #eaeaed;
        }
      }
    }
  }
}
</style>
